var spinnerOpts = {
    lines: 11 // The number of lines to draw
    , length: 6 // The length of each line
    , width: 3 // The line thickness
    , radius: 28 // The radius of the inner circle
    , scale: 1 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#000' // #rgb or #rrggbb or array of colors
    , opacity: 0.25 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: false // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
};
var spinner = null;

function loadSpinner(parent){
    if ( !spinner ) {
        var target = document.getElementById(parent);
        spinner = new Spinner(spinnerOpts).spin(target);
        $(spinner.el).hide();
    }

    $(spinner.el).fadeIn();
}

function removeSpinner(){
    $(spinner.el).fadeOut();
}

$("#login-form #submit").click(function (e) {

    e.preventDefault();

    if ($('#em .cd-error-message').hasClass('is-visible')) {
        $('#em .cd-error-message').removeClass('is-visible');
        $('#em i').css('color', '#a3a3a3');
    }

    if ($('#pm .cd-error-message').hasClass('is-visible')) {
        $('#pm .cd-error-message').removeClass('is-visible');
        $('#pm i').css('color', '#a3a3a3');
    }

    var form = jQuery(this).parents("form:first");
    var dataString = form.serialize();
    var formAction = form.attr('action');

    loadSpinner('user-modal-box');

    $.ajax({
        type: "POST",
        url: formAction,
        data: dataString,
        success: function (data) {
            removeSpinner();

            setTimeout(
                function () {
                    $('#login-form #submit').hide();//.css('background', '#449d44');
                    $('#login-fields').html('<div class="alert alert-success width-50 alert-border p10"><p><i class="fa fa-check"></i>Úspešne prihlásený. Čakajte, budete presmerovaný.</p></div>');

                    setTimeout(
                        function () {
                            if (data.intended) {
                                window.location.href = data.intended;
                            }
                        }, 2000);
                }, 500);
        },
        error: function (data) {
            var errors = $.parseJSON(data.responseText);
            removeSpinner();

            setTimeout(
                function () {
                    if (data.status === 406) {
                        $('#em .cd-error-message').addClass('is-visible');
                        $('#em i').css('color', '#a94442');
                        $('#em .cd-error-message').html('Nesprávne meno alebo heslo.')
                    }

                    if (errors.email) {
                        $('#em .cd-error-message').addClass('is-visible');
                        $('#em i').css('color', '#a94442');
                        $('#em .cd-error-message').html(errors.email);
                    }

                    if (errors.password) {
                        $('#pm .cd-error-message').addClass('is-visible');
                        $('#pm i').css('color', '#a94442');
                        $('#pm .cd-error-message').html(errors.password);
                    }

                }, 500);
        }

    }, "json");
});

$("#register-form #submit").click(function (e) {

    e.preventDefault();

    if ($('.cd-error-message').hasClass('is-visible')) {
        $('.cd-error-message').removeClass('is-visible');
        $('i').css('color', '#a3a3a3');
    }

    var form = jQuery(this).parents("form:first");
    var dataString = form.serialize();
    var formAction = form.attr('action');

    loadSpinner('user-modal-box');

    $.ajax({
        type: "POST",
        url: formAction,
        data: dataString,
        success: function (data) {
            removeSpinner();

            setTimeout(
                function () {
                    $('#register-form #submit').hide();//.css('background', '#449d44');
                    $('#register-fields').html('<div class="alert alert-success width-50 alert-border p10"><p><i class="fa fa-check"></i>Úspešne zaregistrovaný. Prihlasujem ...</p></div>');

                    setTimeout(
                        function () {
                            window.location.href = data.intended
                        }, 3000);
                }, 500);
        },
        error: function (data) {
            var errors = $.parseJSON(data.responseText);
            removeSpinner();

            setTimeout(
                function () {
                    if (data.status === 422) {

                        if (errors.name) {
                            $('#namef .cd-error-message').addClass('is-visible');
                            $('#namef i').css('color', '#a94442');
                            $('#namef .cd-error-message').html(errors.name);
                        }

                        if (errors.surname) {
                            $('#surnamef .cd-error-message').addClass('is-visible');
                            $('#surnamef i').css('color', '#a94442');
                            $('#surnamef .cd-error-message').html(errors.surname);
                        }

                        if (errors.email) {
                            $('#emailf .cd-error-message').addClass('is-visible');
                            $('#emailf i').css('color', '#a94442');
                            $('#emailf .cd-error-message').html(errors.email);
                        }

                        if (errors.password) {
                            $('#passf .cd-error-message').addClass('is-visible');
                            $('#passf i').css('color', '#a94442');
                            $('#passf .cd-error-message').html(errors.password);
                        }

                        if (errors.terms) {
                            $('#termsf .cd-error-message').addClass('is-visible');
                            $('#termsf i').css('color', '#a94442');
                            $('#termsf .cd-error-message').html(errors.terms);
                        }

                        if (errors['terms-2']) {
                            $('#termsf-2 .cd-error-message').addClass('is-visible');
                            $('#termsf-2 i').css('color', '#a94442');
                            $('#termsf-2 .cd-error-message').html(errors['terms-2']);
                        }

                        if (errors.isic) {
                            $('#student-id .cd-error-message').addClass('is-visible');
                            $('#student-id i').css('color', '#a94442');
                            $('#student-id .cd-error-message').html(errors.isic);
                        }

                    }
                }, 500);
        }

    }, "json");
});

$("#remember-form #submit").click(function (e) {

    e.preventDefault();

    if ($('#femailf .cd-error-message').hasClass('is-visible')) {
        $('#femailf .cd-error-message').removeClass('is-visible');
        $('#femailf i').css('color', '#a3a3a3');
    }

    $("#cd-reset-password .cd-form-message").html("" +
        "<img src='assets/images/assets/bx_loader.gif'><br>" +
        "<b>Odosielam ...</b>")

    var form = jQuery(this).parents("form:first");
    var dataString = form.serialize();
    var formAction = form.attr('action');

    $.ajax({
        type: "POST",
        url: formAction,
        data: dataString,
        success: function (data) {
            setTimeout(
                function () {
                    //$('#remember-form #submit').css('background', '#449d44');
                    $("#cd-reset-password .cd-form-message").html("<b>Správa bola odoslaná. Na Váš email príde do niekoľkých minút.</b>");
                    setTimeout(
                        function () {
                            $(".cd-user-modal").removeClass('is-visible');
                        }, 3000);
                }, 500);
        },
        error: function (data) {
            var errors = $.parseJSON(data.responseText);

            setTimeout(
                function () {
                    if (data.status === 422) {

                        if (errors.email) {
                            $('#femailf .cd-error-message').addClass('is-visible');
                            $('#femailf i').css('color', '#a94442');
                            $('#femailf .cd-error-message').html(errors.email);
                        }
                    } else {
                        $("#cd-reset-password .cd-form-message").html("<b>Niekde nastala chyba. Skúste to prosím neskôr.</b>");
                    }
                }, 500);
        }

    }, "json");
});


